<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.anadir') }} {{ $t('general.incidencias') }}</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <label>{{ $t('general.fecha') }} &nbsp;</label>
                </div>
                <div class="col-md-6">
                    <label>{{ $t('general.origenincidencia') }} &nbsp;</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <Calendar style="width: 8rem" id="fnota" v-model="fechanuevaincidencia" dateFormat="dd/mm/yy" />
                </div>
                <div class="col-md-6">
                    <select class="form-control col-md-8" v-model="origenincidencianueva">
                        <option v-for="origen in incidencias.origenes" :key="origen">{{ Object.values(origen).toString() }}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6" v-if="origenincidencianueva == 'Incidencia operario'">     
                    <br>
                    <label>Selecciona operario</label><br >
                    <select v-model="operarioseleccionado" class="form-control col-md-8">
                        <option v-for="operario in operarios.operarios" :key="operario" :value="operario.id">{{operario.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-6" v-if="origenincidencianueva == 'Incidencia tramitador'">
                    <br>
                    <label>Selecciona tramitador</label><br>
                    <select v-model="tramitadorseleccionado" class="form-control col-md-8">
                        <option v-for="tramitador in tramitadores" :key="tramitador" :value="tramitador.id">{{ tramitador.nombre }}</option>
                    </select>
                </div>
                <div class="col-md-6" v-if="origenincidencianueva == 'Incidencia tramitador' || origenincidencianueva == 'Incidencia operario'">
                    <br>
                    <label>Tipo de incidencia</label><br >
                    <select v-model="tipodeincidencia" class="form-control col-md-8">
                        <option v-for="tipo in incidencias.tipos" :key="tipo" :value="tipo.id_tipo">{{tipo.nombre}}</option>
                    </select>          
                </div>
            </div>
            <br>
            <div class="row" v-if="origenincidencianueva">
                <div class="col-md-3">
                    <label>¿Es procedente?</label><br>
                    <select v-model="procedente" class="form-control col-md-8">
                        <option>{{ $t('general.incidenciaprocedente') }}</option>
                        <option>{{ $t('general.incidencianoprocedente') }}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label>{{ $t('general.observaciones') }}</label><br>
                    <Textarea v-model="textonuevaincidencia" cols="30" rows="3" ></Textarea>
                    <br>
                    <p>{{ textonuevaincidencia.length }} {{ $t('general.caracteres') }}</p>
                </div>
                <div class="col-md-3">
                    <label>Incidencia interna</label><br>
                    <Checkbox v-model="interna" :binary="true" />
                </div>
            </div>
            <br>
            <div class="row" v-if="origenincidencianueva" >               
                <div class="col-md-4">
                </div>
                <div class="col-md-4">
                    <Button @click="nuevaincidencia()">{{ $t('general.anadir') }} {{ $t('general.incidencias') }}</Button>
                </div>
            </div>
            </div>
        </div>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.listadoincidencias') }}</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>{{ $t('general.fecha') }}</th>
                            <th>{{ $t('general.origen') }}</th>
                            <th>{{ $t('general.tipologia') }}</th>
                            <th>{{ $t('general.texto') }}</th>
                            <th>{{ $t('general.nivelrga') }}</th>
                            <th>{{ $t('general.procedente') }}</th>
                        </tr>
                    </thead>    
                    <tbody>
                        <tr data-widget="expandable-table" aria-expanded="false" v-for="incidencia in incidencias.incidencias" :key="incidencia">
                            <td>{{ new Date(incidencia.fecha_incidencia_servicio).toLocaleDateString("es-ES", { day: 'numeric' }) + "-" + new Date(incidencia.fecha_incidencia_servicio).toLocaleDateString("es-ES", { month: 'numeric' }) + "-" + new Date(incidencia.fecha_incidencia_servicio).toLocaleDateString("es-ES", { year: 'numeric' }) }}</td>
                            <td><p v-if="incidencia.tipo_incidencia == 1">{{ $t('general.Operario') }}</p><p v-if="incidencia.tipo_incidencia == 2">{{ $t('general.tramitador') }}</p><p v-if="incidencia.tipo_incidencia == 3">Compañia</p></td>
                            <td v-if="incidencia.tipologia!=0">{{ incidencias.tipos[incidencia.tipologia].nombre}}</td><td v-else></td>
                            <td><p v-html="incidencia.texto_incidencia_servicio"></p></td>
                            <td>{{ incidencia.nivel }}</td>
                            <td>{{procede[incidencia.procedencia]}}</td>
                            <td>
                                <Button style="font-size: 0.75rem !important; padding: 0.25rem 0.5rem;" @mouseover="borrar(incidencia.id_incidencia_servicio)" @click="toggle">{{ $t('general.borrar') }}  
                                </Button>
                                <OverlayPanel ref="op"><p style="text-align: center">{{ $t('general.borrarincidencia') }}</p>
                                    <div style="text-align: center">
                                        <Button severity="danger" style="font-size: 0.75rem !important; padding: 0.25rem 0.5rem;background:red; border:1px solid red" @click="borrarincidencia(idaborrar)">{{ $t('general.borrar') }}    
                                        </Button>&nbsp;
                                        <Button style="font-size: 0.75rem !important;  padding: 0.25rem 0.5rem;" @click="toggle">{{ $t('general.cancelar') }}    
                                        </Button>
                                    </div>
                                </OverlayPanel>
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import OverlayPanel from 'primevue/overlaypanel';
//@click="borrarincidencia(incidencia.id_incidencia_servicio)"
export default ({
    props: ['id', 'servicio'],
    data() {
        return {
            incidencias: '',
            fechanuevaincidencia: new Date(),
            origenincidencianueva: '',
            operarios: '',
            operarioseleccionado: '',
            tramitadorseleccionado: '',
            tramitadores: '',
            tipodeincidencia: '',
            procedente: '',
            interna: false,
            textonuevaincidencia: '',
            procede: ['', 'Si', 'No'],
            idaborrar:'',
        }
    },
        setup() {
        return;
    },
        components: {
        Calendar, Button, Checkbox, Textarea, OverlayPanel
    },
    methods: {
        borrar(idaux) {
            this.idaborrar = idaux;
        },
        toggle(event) {
            this.$refs.op[0].toggle(event);
        },
        async cargarincidencias() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/incidencias
            
            const api = new PwgsApi();
            this.incidencias = await api.get('servicios/' + this.$props.id + '/incidencias');
        },
        async borrarincidencia(idincidencia) {
            const api = new PwgsApi();
            await api.delete('servicios/' + this.$props.id + '/incidencias/' + idincidencia);
            this.cargarincidencias();
        },
        async cargaroperarios() {
          //GET   modulos/pwgsapi/index.php/servicios/:id/operarios
            const api = new PwgsApi();
            this.operarios = await api.get('servicios/' + this.$props.id + '/operarios');
        },
        async cargartramitadores() {
            //GET  modulos/pwgsapi/index.php/usuarios/tramitadores
            const api = new PwgsApi();
            let aux = await api.get('usuarios/tramitadores');
            this.tramitadores = aux.datos;
        },
        async nuevaincidencia() {
            //POST  modulos/pwgsapi/index.php/servicios/:id/incidencias
            const api = new PwgsApi();
            let origen = '';
            let procedencia = '';
            let esinterna = '';
            let fechaformat = new Date(this.fechanuevaincidencia).toLocaleDateString("es-ES", { day: 'numeric' }) + "/" + new Date(this.fechanuevaincidencia).toLocaleDateString("es-ES", { month: 'numeric' }) + "/" + new Date(this.fechanuevaincidencia).toLocaleDateString("es-ES", { year: 'numeric' });
            if (this.origenincidencianueva == 'Incidencia operario'){origen=1}
            if (this.origenincidencianueva == 'Incidencia tramitador'){origen=2}
            if (this.origenincidencianueva == 'Incidencia recibida de compañía'){origen=3}
            if (this.procedente == "Incidencia no procedente"){procedencia=2}
            if (this.procedente == "Incidencia procedente"){procedencia=1}
            if (this.interna == false){esinterna=0}
            if (this.interna == true){esinterna=1}
            let subidadatos = { origen: origen, tipo: this.tipodeincidencia, procedencia: procedencia, fecha: fechaformat, interna: esinterna, texto: this.textonuevaincidencia, id_operario: this.operarioseleccionado, id_usuario: this.tramitadorseleccionado }
            try {
                await api.post('servicios/' + this.$props.id + '/incidencias', subidadatos);
                this.formateardatos();
            }
             catch (error) {
               this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.cargarincidencias();
        },
        formateardatos() {
            this.origenincidencianueva = '';
            this.operarioseleccionado = '';
            this.tramitadorseleccionado = '';
            this.tipodeincidencia = '';
            this.procedente = '';
            this.textonuevaincidencia = '';
        }
    },
    mounted() {
        this.cargarincidencias();
        this.cargaroperarios();
        this.cargartramitadores();
    },
    watch: {
        id() {
            this.cargarincidencias();
            this.cargaroperarios();
            this.formateardatos();
        },
    }
})
</script>